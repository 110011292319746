<template>
  <div class="record">
    <section class="head">
      <h1>{{ examInfo.examName }}</h1>
      <div class="head-info">
        <div class="user">
          <div class="img">
            <img :src="paperData.portrait" alt="portrait" />
          </div>
          <div>
            <p>
              <span
                >{{ $t("Pub_Lab_UserName") }}：
                <OpenData type="userName" :openid="paperData.realName" />
              </span>
              <!-- 姓名： -->
              <span>{{ $t("cm_account") }}：{{ paperData.account }}</span>
              <!-- 账号： -->
            </p>
            <p class="depart" v-if="paperData.department">
              <!-- 部门： -->
              {{ $t("CM_Department") }}：<template
                v-for="(str, strIndex) in paperData.department.split('/')"
                :key="strIndex"
              >
                <OpenData type="departmentName" :openid="str" />
                <span
                  v-if="strIndex + 1 < paperData.department.split('/').length"
                  >/</span
                >
              </template>
            </p>
          </div>
        </div>
        <div class="signature">
          <img :src="paperData.signImage" v-if="paperData.signImage" />
        </div>
      </div>
    </section>

    <section class="statistics">
      <div class="content">
        <a-row :gutter="16">
          <a-col :span="18">
            <a-row :gutter="10">
              <a-col :span="8">
                <p>
                  {{ $t("practice.paper_total_score") }}：{{
                    examInfo.totalScore
                  }}
                  {{ $t("exam.score") }}
                </p>
                <!-- 试卷总分： -->
              </a-col>
              <a-col :span="8">
                <p>
                  {{ $t("exam.passing_score") }}：{{ examInfo.passScore }}
                  {{ $t("exam.score") }}
                </p>
                <!-- 及格分数： -->
              </a-col>
              <a-col :span="8">
                <p>
                  {{ $t("exam.exam_score") }}：{{
                    paperData.status == 1
                      ? "--"
                      : paperData.score + " " + $t("exam.score")
                  }}
                  <!-- 考试得分： -->
                </p>
              </a-col>
              <a-col :span="8">
                <p>
                  {{ $t("exam.use_time") }}：{{
                    formatSeconds(paperData.useTime)
                  }}
                </p>
                <!-- 考试用时： -->
              </a-col>
              <a-col :span="8">
                <p>
                  {{ $t("exam.join_nums") }}：{{ paperData.mineTimes
                  }}{{ $t("practice.number_of_times") }}
                </p>
                <!-- 参与次数： -->
              </a-col>
              <a-col :span="8">
                <p>
                  {{ $t("LB_Exam_CutScreenNum") }}：{{ paperData.switchNum
                  }}{{ $t("practice.number_of_times") }}
                </p>
                <!-- 切屏次数： -->
              </a-col>
              <a-col :span="8" v-if="state.faceList.length">
                <p>
                  {{ $t("NumberOfIdentifications") }}：{{ state.faceList.length
                  }}{{ $t("practice.number_of_times") }}
                </p>
                <!-- 识别次数： -->
              </a-col>
              <a-col :span="8">
                <p>
                  <!-- 本次考试状态： -->
                  {{ $t("exam.status_of_this") }}：
                  <span v-if="paperData.status == 1">{{
                    $t("XB_Exam_PendingApproval")
                  }}</span>
                  <!-- 待批阅 -->
                  <span style="color: red" v-if="paperData.status == 2">{{
                    $t("CM_NoPass")
                  }}</span>
                  <!-- 未通过 -->
                  <span style="color: green" v-if="paperData.status == 8">{{
                    $t("Pub_Passed")
                  }}</span>
                  <!-- 已通过 -->
                  <span style="color: green" v-if="paperData.status == 9">{{
                    $t("Pub_Passed")
                  }}</span>
                  <!-- 已通过 -->
                </p>
              </a-col>
              <a-col :span="8">
                <p v-if="isAdmin">
                  {{ $t("CM_SubmitTime") }}：<span class="time">{{
                    dateFormat(paperData.submitTime)
                  }}</span>
                  <!-- 提交时间： -->
                </p>
                <p v-else>
                  {{ $t("exam.exam_status") }}：
                  <!-- 本场考试状态： -->
                  <span v-if="paperData.examStatus == 1">{{
                    $t("XB_Exam_PendingApproval")
                  }}</span>
                  <!-- 待批阅 -->
                  <span style="color: red" v-if="paperData.examStatus == 2">{{
                    $t("CM_NoPass")
                  }}</span>
                  <!-- 未通过 -->
                  <span style="color: green" v-if="paperData.examStatus == 8">{{
                    $t("Pub_Passed")
                  }}</span>
                  <!-- 已通过 -->
                  <span style="color: green" v-if="paperData.examStatus == 9">
                    {{
                      paperData.reexamId
                        ? $t("Pub_Passed")
                        : $t("exam.make_up_pass")
                    }}
                    <!-- "已通过" : "补考通过" -->
                  </span>
                </p>
              </a-col>
              <a-col :span="8" v-if="!isAdmin">
                <p>
                  {{ $t("CM_SubmitTime") }}：{{
                    dateFormat(paperData.submitTime)
                  }}
                  <!-- 提交时间： -->
                </p>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6">
            <div class="historys no-print" v-if="isAdmin">
              <!-- 苹果电脑不支持导出，请浏览器访问平台进行导出操作 -->
              <a-tooltip
                placement="topRight"
                :title="$t('exam.ios_export_tip')"
                v-if="isMac && isWxWork"
              >
                <a-button type="primary">{{
                  $t("exam.export_answer")
                }}</a-button>
                <!-- 导出答卷 -->
              </a-tooltip>
              <a-button type="primary" v-else @click="exportPaper">{{
                $t("exam.export_answer")
              }}</a-button>
              <!-- 导出答卷 -->
            </div>
            <div class="historys no-print" v-else>
              <a-select
                v-model:value="submitId"
                style="width: 320px"
                @change="historyChange"
              >
                <a-select-option
                  v-for="item in examInfo.historys"
                  :key="item.submitId"
                  :value="item.submitId"
                  >{{ dateFormat(item.startTime) }} -
                  {{ dateFormat(item.submitTime) }}</a-select-option
                >
              </a-select>
            </div>
          </a-col>
        </a-row>
      </div>
    </section>

    <section class="face-record" v-if="state.faceList.length">
      <div class="content">
        <div class="title">人脸识别记录</div>
        <div class="face">
          <div class="face-item" v-for="(item, i) in state.faceList" :key="i">
            <div class="img">
              <img :src="item.facePhoto" alt="人脸" />
            </div>
            <div class="info">
              <div class="time">
                {{ dateFormat(item.createTime) }}
              </div>
              <div class="status">
                <!-- 人脸匹配: -->
                {{ $t("cm_face_matching") }}:
                <!-- 成功 失败 -->
                <span :class="item.taskStatus == 1 ? 'success' : 'error'">{{
                  item.taskStatus == 1 ? $t("Success") : $t("Error")
                }}</span>
              </div>
            </div>
            <div class="tag">
              <!-- 学习前 学习中 -->
              {{
                item.taskRule == 1 ? $t("BeforeTheExam") : $t("DuringAnExam")
              }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="main">
      <div class="content">
        <div class="water-marks" v-if="openViewWatermark">
          <div class="item" v-for="i in 200" :key="i">
            <OpenData type="userName" :openid="userInfo.realName" />({{
              userInfo.account
            }})
            <OpenData type="departmentName" :openid="userInfo.departmentName" />
          </div>
        </div>
        <div class="questions" :style="{ width: isAdmin ? '100%' : '894px' }">
          <div
            class="type-path"
            v-for="(path, index) in quesObj.values()"
            :key="index"
          >
            <template v-if="path.data.length && path.type !== 8">
              <div class="headline">
                <span class="classify">
                  {{ toChinesNum(index + 1) }}、{{ path.name }}
                </span>
                <span class="score">
                  （
                  {{
                    $t("exam.case_title", [path.data.length, path.totalScore])
                  }}
                  ）
                  <!-- 本大题共{0}小题，共{1}分 -->
                </span>
              </div>
              <div
                class="questions-item"
                v-for="(item, queIndex) in path.data"
                :key="item.questionId"
                :id="'que_' + item.questionId"
              >
                <div class="questions-item-head">
                  <div class="type-title">
                    {{ queIndex + 1 }}.{{ path.name }}
                    <span>（{{ $t("exam.nth_score", [item.score]) }}）</span>
                    <!-- 分 -->
                  </div>
                </div>
                <template v-if="path.type === 1 || path.type === 3">
                  <p class="title">{{ item.title }}</p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                  <div
                    class="opt-radio"
                    :class="{
                      active: opt.isRight,
                      right: item.answers.includes(opt.id),
                    }"
                    v-for="(opt, optIndex) in item.options"
                    :key="opt.id"
                  >
                    <div class="opt-item">
                      <div class="letter">{{ letterOpts[optIndex] }}</div>
                      <div>
                        <span class="text">{{ opt.title }}</span>
                        <exam-media
                          v-if="opt.img"
                          type="option"
                          :mediaList="[opt.img]"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="answer">
                    <div class="symbol">
                      <CheckSquareFilled
                        class="right"
                        v-if="item.myScore > 0"
                      />
                      <CloseSquareFilled class="wrong" v-else />
                    </div>
                    <div class="info">
                      <p>
                        <span class="right">
                          <b>{{ $t("exam.right_answer") }}：</b>
                          <!-- 正确答案： -->
                          <i>{{ letterOpts[item.correctAnswer] }}</i>
                        </span>
                        <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                          <b>{{ $t("exam.my_answer") }}：</b>
                          <!-- 我的答案： -->
                          <i>{{ letterOpts[item.userAnswer] }}</i>
                        </span>
                        <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                          <b>{{ $t("exam.my_scoring") }}：</b>
                          <!-- 我的得分： -->
                          <i>{{ item.myScore }}</i>
                        </span>
                        <a-popconfirm
                          v-if="canEditScore && item.myScore < item.score"
                          :title="$t('exam.modify_score_tip')"
                          @confirm="editScore(item)"
                        >
                          <!-- 确定修改题目的得分吗？ -->
                          <span class="edit-score">
                            <EditOutlined />{{ $t("cm_modify") }}
                          </span>
                          <!-- 修改 -->
                        </a-popconfirm>
                      </p>
                      <p v-if="item.analysis || item.analysisimgs">
                        <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                        >{{ item.analysis }}
                        <!-- 答题解析： -->
                        <analysis-media
                          v-if="item.analysisimgs"
                          :data="item.analysisimgs"
                        />
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else-if="path.type === 2">
                  <p class="title">{{ item.title }}</p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                  <div>
                    <div
                      class="opt-checkbox"
                      :class="{
                        active: opt.isRight,
                        right: item.answers.includes(opt.id),
                      }"
                      v-for="(opt, optIndex) in item.options"
                      :key="opt.id"
                    >
                      <div class="opt-item">
                        <div
                          class="letter"
                          :class="{
                            wrong:
                              !opt.isRight && item.answers.includes(opt.id),
                          }"
                        >
                          {{ letterOpts[optIndex] }}
                        </div>
                        <div>
                          <span
                            class="text"
                            :class="{
                              wrong:
                                opt.isRight && !item.answers.includes(opt.id),
                            }"
                          >
                            {{ opt.title }}
                          </span>
                          <exam-media
                            v-if="opt.img"
                            type="option"
                            :mediaList="[opt.img]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="answer">
                    <div class="symbol">
                      <CheckSquareFilled
                        class="right"
                        v-if="item.myScore > 0"
                      />
                      <CloseSquareFilled class="wrong" v-else />
                    </div>
                    <div class="info">
                      <p>
                        <span class="right">
                          <b>{{ $t("exam.right_answer") }}：</b>
                          <!-- 正确答案： -->
                          <i v-for="sele in item.correctAnswer" :key="sele">
                            {{ letterOpts[sele] }}
                          </i>
                        </span>
                        <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                          <b>{{ $t("exam.my_answer") }}：</b>
                          <!-- 我的答案： -->
                          <i v-for="sele in item.userAnswer" :key="sele">
                            {{ letterOpts[sele] }}
                          </i>
                        </span>
                        <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                          <b>{{ $t("exam.my_scoring") }}：</b>
                          <!-- 我的得分： -->
                          <i>{{ item.myScore }}</i>
                        </span>
                        <a-popconfirm
                          v-if="canEditScore && item.myScore < item.score"
                          :title="$t('exam.modify_score_tip')"
                          @confirm="editScore(item)"
                        >
                          <!-- 确定修改题目的得分吗？ -->
                          <span class="edit-score">
                            <EditOutlined />{{ $t("cm_modify") }}
                          </span>
                          <!-- 修改 -->
                        </a-popconfirm>
                      </p>
                      <p v-if="item.analysis || item.analysisimgs">
                        <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                        >{{ item.analysis }}
                        <!-- 答题解析： -->
                        <analysis-media
                          v-if="item.analysisimgs"
                          :data="item.analysisimgs"
                        />
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else-if="path.type === 4">
                  <p class="title">
                    <template
                      v-for="(opt, optIndex) in item.options"
                      :key="opt.id"
                    >
                      <span>{{ item.titles[optIndex] || "、" }}</span>
                      <span class="opt-input">
                        {{ opt.title }}
                        <b v-if="item.strAnswer">
                          <span>【</span>
                          <i
                            v-for="(text, textIndex) in JSON.parse(
                              item.strAnswer
                            )[optIndex].split('$')"
                            :key="textIndex"
                          >
                            {{ text }}
                          </i>
                          <span>】</span>
                        </b>
                      </span>
                    </template>
                    <span v-if="item.titles.length > item.options.length">
                      {{ item.titles[item.titles.length - 1] }}
                    </span>
                  </p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                  <div class="answer">
                    <div class="symbol">
                      <CheckSquareFilled
                        class="right"
                        v-if="item.myScore > 0"
                      />
                      <CloseSquareFilled class="wrong" v-else />
                    </div>
                    <div class="info">
                      <p>
                        <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                          <b>{{ $t("exam.my_scoring") }}：</b>
                          <!-- 我的得分： -->
                          <i>{{ item.myScore }}</i>
                        </span>
                        <a-popconfirm
                          v-if="canEditScore && item.myScore < item.score"
                          @confirm="editScore(item)"
                        >
                          <template #title>
                            {{ $t("exam.modify_score_tip2") }}
                            <a-input-number
                              style="width: 70px"
                              v-model:value="item.editScore"
                              :min="item.myScore"
                              :max="item.score"
                              :precision="1"
                            />
                            {{ $t("exam.modify_score_tip3") }}
                          </template>
                          <!-- 确定修改题目的得分吗？ -->
                          <span class="edit-score">
                            <EditOutlined />{{ $t("cm_modify") }}
                          </span>
                          <!-- 修改 -->
                        </a-popconfirm>
                      </p>
                      <p v-if="item.analysis || item.analysisimgs">
                        <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                        >{{ item.analysis }}
                        <!-- 答题解析： -->
                        <analysis-media
                          v-if="item.analysisimgs"
                          :data="item.analysisimgs"
                        />
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else-if="path.type === 5">
                  <p class="title">{{ item.title }}</p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                  <div class="opt-textarea">{{ item.options[0].title }}</div>
                  <div
                    class="opt-pics"
                    v-viewer="{ modal: true, title: false }"
                    v-if="item.answerImg"
                  >
                    <div
                      class="opt-pics-item ed"
                      v-for="(itemPic, itemPicIndex) in item.answerImg"
                      :key="itemPicIndex"
                    >
                      <img :src="itemPic" alt="picture" />
                    </div>
                  </div>
                  <div class="answer">
                    <div class="symbol" v-if="paperData.status != 1">
                      <CheckSquareFilled
                        class="right"
                        v-if="item.myScore > 0"
                      />
                      <CloseSquareFilled class="wrong" v-else />
                    </div>
                    <div class="info">
                      <p>
                        <span :class="item.myScore > 0 ? 'right' : 'wrong'">
                          <b>{{ $t("exam.my_scoring") }}：</b>
                          <!-- 我的得分： -->
                          <i>{{
                            paperData.status == 1
                              ? item.answer1Arr &&
                                item.answer1Arr.length &&
                                paperData.answerReview != 1
                                ? item.myScore
                                : "--"
                              : item.myScore
                          }}</i>
                        </span>
                        <a-popconfirm
                          v-if="canEditScore && item.myScore < item.score"
                          @confirm="editScore(item)"
                        >
                          <template #title>
                            {{ $t("exam.modify_score_tip2") }}
                            <a-input-number
                              style="width: 70px"
                              v-model:value="item.editScore"
                              :min="item.myScore"
                              :max="item.score"
                              :precision="1"
                            />
                            {{ $t("exam.modify_score_tip3") }}
                          </template>
                          <!-- 确定修改题目的得分吗？ -->
                          <span class="edit-score">
                            <EditOutlined />{{ $t("cm_modify") }}
                          </span>
                          <!-- 修改 -->
                        </a-popconfirm>
                      </p>
                      <div v-if="item.answer1Arr && item.answer1Arr.length">
                        <b>{{ $t("exam.score_keywords") }}</b>
                        <!-- 判分关键词 -->
                        <p
                          v-for="(keyword, keywordIndex) in item.answer1Arr"
                          :key="keywordIndex"
                        >
                          <!-- 关键词 -->
                          {{ $t("exam.keyword") }}{{ keywordIndex + 1 }}：{{
                            keyword.option.split("$").join("、")
                          }}
                        </p>
                      </div>
                      <div v-if="item.analysis || item.analysisimgs">
                        <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                        >{{ item.analysis }}
                        <!-- 答题解析： -->
                        <analysis-media
                          v-if="item.analysisimgs"
                          :data="item.analysisimgs"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-if="path.data.length && path.type === 8">
              <div v-for="(caseItem, caseIndex) in path.data" :Key="caseIndex">
                <div class="headline">
                  <span class="classify">
                    {{ toChinesNum(index + caseIndex + 1) }}、{{ path.name }}
                  </span>
                  <span class="score">
                    （
                    {{
                      $t("exam.case_title", [
                        caseItem.list.length,
                        caseItem.score,
                      ])
                    }}
                    ）
                    <!-- 本大题共{0}小题，共{1}分 -->
                  </span>
                </div>
                <div class="case-box">
                  <div class="case-box-head">
                    <p class="title">{{ caseItem.title }}</p>
                    <template
                      v-if="caseItem.titimgs && caseItem.titimgs.length"
                    >
                      <exam-media :mediaList="caseItem.titimgs" />
                    </template>
                  </div>
                  <div
                    class="questions-item"
                    v-for="(item2, queIndex2) in caseItem.list"
                    :key="item2.questionId"
                    :id="'que_' + item2.questionId"
                  >
                    <div class="questions-item-head">
                      <div class="type-title">
                        {{ queIndex2 + 1 }}.{{
                          questionTypeEnum[item2.questionType]
                        }}
                        <span
                          >（{{ $t("exam.nth_score", [item2.score]) }}）</span
                        >
                        <!-- 分） -->
                      </div>
                    </div>
                    <template
                      v-if="
                        item2.questionType === 1 || item2.questionType === 3
                      "
                    >
                      <p class="title">{{ item2.title }}</p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                      <div
                        class="opt-radio"
                        :class="{
                          active: opt.isRight,
                          right: item2.answers.includes(opt.id),
                        }"
                        v-for="(opt, optIndex) in item2.options"
                        :key="opt.id"
                      >
                        <div class="opt-item">
                          <div class="letter">{{ letterOpts[optIndex] }}</div>
                          <div>
                            <span class="text">{{ opt.title }}</span>
                            <exam-media
                              v-if="opt.img"
                              type="option"
                              :mediaList="[opt.img]"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="answer">
                        <div class="symbol">
                          <CheckSquareFilled
                            class="right"
                            v-if="item2.myScore > 0"
                          />
                          <CloseSquareFilled class="wrong" v-else />
                        </div>
                        <div class="info">
                          <p>
                            <span class="right">
                              <b>{{ $t("exam.right_answer") }}：</b>
                              <!-- 正确答案： -->
                              <i>{{ letterOpts[item2.correctAnswer] }}</i>
                            </span>
                            <span
                              :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            >
                              <b>{{ $t("exam.my_answer") }}：</b>
                              <!-- 我的答案： -->
                              <i>{{ letterOpts[item2.userAnswer] }}</i>
                            </span>
                            <span
                              :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            >
                              <b>{{ $t("exam.my_scoring") }}：</b>
                              <!-- 我的得分： -->
                              <i>{{ item2.myScore }}</i>
                            </span>
                            <a-popconfirm
                              v-if="canEditScore && item2.myScore < item2.score"
                              :title="$t('exam.modify_score_tip')"
                              @confirm="editScore(item2)"
                            >
                              <!-- 确定修改题目的得分吗？ -->
                              <span class="edit-score">
                                <EditOutlined />{{ $t("cm_modify") }}
                                <!-- 修改 -->
                              </span>
                            </a-popconfirm>
                          </p>
                          <p v-if="item2.analysis || item2.analysisimgs">
                            <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                            >{{ item2.analysis }}
                            <!-- 答题解析： -->
                            <analysis-media
                              v-if="item2.analysisimgs"
                              :data="item2.analysisimgs"
                            />
                          </p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item2.questionType === 2">
                      <p class="title">{{ item2.title }}</p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                      <div>
                        <div
                          class="opt-checkbox"
                          :class="{
                            active: opt.isRight,
                            right: item2.answers.includes(opt.id),
                          }"
                          v-for="(opt, optIndex) in item2.options"
                          :key="opt.id"
                        >
                          <div class="opt-item">
                            <div
                              class="letter"
                              :class="{
                                wrong:
                                  !opt.isRight &&
                                  item2.answers.includes(opt.id),
                              }"
                            >
                              {{ letterOpts[optIndex] }}
                            </div>
                            <div>
                              <span
                                class="text"
                                :class="{
                                  wrong:
                                    opt.isRight &&
                                    !item2.answers.includes(opt.id),
                                }"
                              >
                                {{ opt.title }}
                              </span>
                              <exam-media
                                v-if="opt.img"
                                type="option"
                                :mediaList="[opt.img]"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="answer">
                        <div class="symbol">
                          <CheckSquareFilled
                            class="right"
                            v-if="item2.myScore > 0"
                          />
                          <CloseSquareFilled class="wrong" v-else />
                        </div>
                        <div class="info">
                          <p>
                            <span class="right">
                              <b>{{ $t("exam.right_answer") }}：</b>
                              <!-- 正确答案： -->
                              <i
                                v-for="sele in item2.correctAnswer"
                                :key="sele"
                              >
                                {{ letterOpts[sele] }}
                              </i>
                            </span>
                            <span
                              :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            >
                              <b>{{ $t("exam.my_answer") }}：</b>
                              <!-- 我的答案： -->
                              <i v-for="sele in item2.userAnswer" :key="sele">
                                {{ letterOpts[sele] }}
                              </i>
                            </span>
                            <span
                              :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            >
                              <b>{{ $t("exam.my_scoring") }}：</b>
                              <!-- 我的得分： -->
                              <i>{{ item2.myScore }}</i>
                            </span>
                            <a-popconfirm
                              v-if="canEditScore && item2.myScore < item2.score"
                              :title="$t('exam.modify_score_tip')"
                              @confirm="editScore(item2)"
                            >
                              <!-- 确定修改题目的得分吗？ -->
                              <span class="edit-score">
                                <EditOutlined />{{ $t("cm_modify") }}
                                <!-- 修改 -->
                              </span>
                            </a-popconfirm>
                          </p>
                          <p v-if="item2.analysis || item2.analysisimgs">
                            <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                            >{{ item2.analysis }}
                            <!-- 答题解析： -->
                            <analysis-media
                              v-if="item2.analysisimgs"
                              :data="item2.analysisimgs"
                            />
                          </p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item2.questionType === 4">
                      <p class="title">
                        <template
                          v-for="(opt, optIndex) in item2.options"
                          :key="opt.id"
                        >
                          <span>{{ item2.titles[optIndex] || "、" }}</span>
                          <span class="opt-input">
                            {{ opt.title }}
                            <b v-if="item2.strAnswer">
                              <span>【</span>
                              <i
                                v-for="(text, textIndex) in JSON.parse(
                                  item2.strAnswer
                                )[optIndex].split('$')"
                                :key="textIndex"
                              >
                                {{ text }}
                              </i>
                              <span>】</span>
                            </b>
                          </span>
                        </template>
                        <span v-if="item2.titles.length > item2.options.length">
                          {{ item2.titles[item2.titles.length - 1] }}
                        </span>
                      </p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                      <div class="answer">
                        <div class="symbol">
                          <CheckSquareFilled
                            class="right"
                            v-if="item2.myScore > 0"
                          />
                          <CloseSquareFilled class="wrong" v-else />
                        </div>
                        <div class="info">
                          <p>
                            <span
                              :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            >
                              <b>{{ $t("exam.my_scoring") }}：</b>
                              <!-- 我的得分： -->
                              <i>{{ item2.myScore }}</i>
                            </span>
                            <a-popconfirm
                              v-if="canEditScore && item2.myScore < item2.score"
                              @confirm="editScore(item2)"
                            >
                              <template #title>
                                {{ $t("exam.modify_score_tip2") }}
                                <a-input-number
                                  style="width: 70px"
                                  v-model:value="item2.editScore"
                                  :min="item2.myScore"
                                  :max="item2.score"
                                  :precision="1"
                                />
                                {{ $t("exam.modify_score_tip3") }}
                              </template>
                              <!-- 确定修改题目的得分吗？ -->
                              <span class="edit-score">
                                <EditOutlined />{{ $t("cm_modify") }}
                                <!-- 修改 -->
                              </span>
                            </a-popconfirm>
                          </p>
                          <p v-if="item2.analysis || item2.analysisimgs">
                            <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                            >{{ item2.analysis }}
                            <!-- 答题解析： -->
                            <analysis-media
                              v-if="item2.analysisimgs"
                              :data="item2.analysisimgs"
                            />
                          </p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item2.questionType === 5">
                      <p class="title">{{ item2.title }}</p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                      <div class="opt-textarea">
                        {{ item2.options[0].title }}
                      </div>
                      <div
                        class="opt-pics"
                        v-viewer="{ modal: true, title: false }"
                        v-if="item2.answerImg"
                      >
                        <div
                          class="opt-pics-item ed"
                          v-for="(itemPic, itemPicIndex) in item2.answerImg"
                          :key="itemPicIndex"
                        >
                          <img :src="itemPic" alt="picture" />
                        </div>
                      </div>
                      <div class="answer">
                        <div class="symbol" v-if="paperData.status != 1">
                          <CheckSquareFilled
                            class="right"
                            v-if="item2.myScore > 0"
                          />
                          <CloseSquareFilled class="wrong" v-else />
                        </div>
                        <div class="info">
                          <p>
                            <span
                              :class="item2.myScore > 0 ? 'right' : 'wrong'"
                            >
                              <b>{{ $t("exam.my_scoring") }}：</b>
                              <!-- 我的得分： -->
                              <i>{{
                                paperData.status == 1
                                  ? item2.answer1Arr &&
                                    item2.answer1Arr.length &&
                                    paperData.answerReview != 1
                                    ? item2.myScore
                                    : "--"
                                  : item2.myScore
                              }}</i>
                            </span>
                            <a-popconfirm
                              v-if="canEditScore && item2.myScore < item2.score"
                              @confirm="editScore(item2)"
                            >
                              <template #title>
                                {{ $t("exam.modify_score_tip2") }}
                                <a-input-number
                                  style="width: 70px"
                                  v-model:value="item2.editScore"
                                  :min="item2.myScore"
                                  :max="item2.score"
                                  :precision="1"
                                />
                                {{ $t("exam.modify_score_tip3") }}
                              </template>
                              <!-- 确定修改题目的得分吗？ -->
                              <span class="edit-score">
                                <EditOutlined />{{ $t("cm_modify") }}
                              </span>
                              <!-- 修改 -->
                            </a-popconfirm>
                          </p>
                          <div
                            v-if="item2.answer1Arr && item2.answer1Arr.length"
                          >
                            <b>{{ $t("exam.score_keywords") }}</b>
                            <!-- 判分关键词 -->
                            <p
                              v-for="(
                                keyword, keywordIndex
                              ) in item2.answer1Arr"
                              :key="keywordIndex"
                            >
                              <!-- 关键词 -->
                              {{ $t("exam.keyword") }}{{ keywordIndex + 1 }}：{{
                                keyword.option.split("$").join("、")
                              }}
                            </p>
                          </div>
                          <div v-if="item2.analysis || item2.analysisimgs">
                            <b>{{ $t("LB_Exam_QuestionAnalysis") }}：</b
                            >{{ item2.analysis }}
                            <!-- 答题解析： -->
                            <analysis-media
                              v-if="item2.analysisimgs"
                              :data="item2.analysisimgs"
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="sidebar" v-if="!isAdmin">
          <div class="sidebar-content">
            <div class="sheet">
              <h5>{{ $t("LB_AnswerSheet") }}</h5>
              <!-- 答题卡 -->
              <div class="label">
                <div class="item"><span></span>{{ $t("CM_Correct") }}</div>
                <!-- 答对 -->
                <div class="item"><span></span>{{ $t("CM_Wrong") }}</div>
                <!-- 答错 -->
                <div class="item"><span></span>{{ $t("CM_NoAnswer") }}</div>
                <!-- 未答 -->
              </div>
              <div class="sheet-list">
                <div
                  class="type-path"
                  v-for="(path, index) in quesObj.values()"
                  :key="index"
                >
                  <template v-if="path.data.length && path.type !== 8">
                    <div class="classify">{{ path.name }}</div>
                    <div class="list clearfix">
                      <template v-if="path.type === 1">
                        <div
                          class="item"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            right:
                              item.userAnswer > -1 !== '' && item.myScore > 0,
                            wrong: item.userAnswer > -1 && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 2">
                        <div
                          class="item"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            right: item.userAnswer.length && item.myScore > 0,
                            wrong: item.userAnswer.length && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 3">
                        <div
                          class="item"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            right:
                              item.userAnswer > -1 !== '' && item.myScore > 0,
                            wrong: item.userAnswer > -1 && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 4">
                        <div
                          class="item"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            right: item.userAnswer.length && item.myScore > 0,
                            wrong: item.userAnswer.length && item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 5">
                        <div
                          class="item"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            right:
                              item.userAnswer.length ||
                              item.answerImg.length ||
                              item.myScore > 0,
                            wrong:
                              (item.userAnswer.length ||
                                item.answerImg.length) &&
                              item.myScore == 0,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-if="path.data.length && path.type === 8">
                    <div
                      v-for="(caseItem, caseIndex) in path.data"
                      :Key="caseIndex"
                    >
                      <div class="classify">
                        {{ toChinesNum(index + caseIndex + 1) }}、{{
                          path.name
                        }}
                      </div>
                      <div class="list clearfix">
                        <template
                          v-for="(item, queIndex) in caseItem.list"
                          :key="item.questionId"
                        >
                          <div
                            class="item"
                            v-if="item.questionType === 1"
                            :class="{
                              right:
                                item.userAnswer > -1 !== '' && item.myScore > 0,
                              wrong: item.userAnswer > -1 && item.myScore == 0,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item"
                            v-if="item.questionType === 2"
                            :class="{
                              right: item.userAnswer.length && item.myScore > 0,
                              wrong:
                                item.userAnswer.length && item.myScore == 0,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item"
                            v-if="item.questionType === 3"
                            :class="{
                              right:
                                item.userAnswer > -1 !== '' && item.myScore > 0,
                              wrong: item.userAnswer > -1 && item.myScore == 0,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item"
                            v-if="item.questionType === 4"
                            :class="{
                              right: item.userAnswer.length && item.myScore > 0,
                              wrong:
                                item.userAnswer.length && item.myScore == 0,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item"
                            v-if="item.questionType === 5"
                            :class="{
                              right:
                                item.userAnswer.length ||
                                item.answerImg.length ||
                                item.myScore > 0,
                              wrong:
                                (item.userAnswer.length ||
                                  item.answerImg.length) &&
                                item.myScore == 0,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="btn">
                <a-button
                  type="primary"
                  block
                  v-if="
                    examInfo.status != 1 &&
                    examInfo.startTime <= currentTime &&
                    examInfo.endTime >= currentTime &&
                    (examInfo.examTimes == -2 ||
                      (examInfo.examTimes == -1 && examInfo.myTimes <= 0) ||
                      (examInfo.examTimes > 0 &&
                        examInfo.myTimes < examInfo.examTimes))
                  "
                  @click="jump"
                  >{{ $t("exam.exam_again") }}</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, computed, toRefs } from "vue";
import { useRoute } from "vue-router";
import { questionTypeEnum, letterOpts } from "@/utils/business";
import {
  formatSeconds,
  toChinesNum,
  dateFormat,
  upFavicon,
} from "@/utils/tools";
import ls from "@/utils/local-storage";
import { currentHost } from "@/utils/request.js";
import { platformConfig } from "@/api/other";
import { wxworkConfig, companyInfo } from "@/api/user";
import { getTaskDetail, getTaskDetailLms } from "@/api/project";
import {
  examResult,
  submitPaperDetail,
  examModify,
  getRetakeDetail,
} from "@/api/exam";
import { plusDetail, coursExamPaperDetail } from "@/api/course";
import { getCustomerConfig } from "@/api/mine";
import OpenData from "@/components/OpenData.vue";
import examMedia from "./exam-media.vue";
import analysisMedia from "./analysis-media.vue";

export default {
  components: {
    OpenData,
    examMedia,
    analysisMedia,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const queryData = route.query;
    const qusetionTotal = ref(0);
    const taskId = parseInt(queryData.taskId || 0),
      detailId = parseInt(queryData.detailId || 0),
      paperId = parseInt(queryData.paperId || 0),
      reexamId = parseInt(queryData.reexamId || 0),
      submitId = ref(parseInt(queryData.submitId || 0)),
      userId = parseInt(queryData.userId || 0),
      isAdmin = queryData.admin == 1,
      totalScore = parseInt(queryData.totalScore || 0),
      limitTime = parseInt(queryData.limitTime || 0),
      courseId = parseInt(queryData.courseId || 0);

    const state = reactive({
      params: {
        taskId: taskId,
        detailId: detailId,
      },
      courseApi: "",
      faceList: [],
      openViewWatermark: false,
      userInfo: ls.get("userInfo"),
    });

    getCustomerConfig({ site: currentHost }).then((res) => {
      let d = res.data || [];
      d.forEach((item) => {
        if (item.configKey == "viewWatermark") {
          if (item.configValue.includes("3")) state.openViewWatermark = true;
        }
      });
    });

    companyInfo().then((res) => {
      if (res.ret == 0) {
        if (res.data.logo2) upFavicon(res.data.logo2);
      }
    });

    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    let isWxWork = /wxwork/i.test(ua);
    let isDingding = /dingtalk/i.test(ua);
    let isMac = /macintosh|mac os x/i.test(ua);
    platformConfig({ site: currentHost }).then((res) => {
      if (res.data.platform == 26) {
        if (isWxWork) {
          console.log("isWxWork");
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.search,
            jsTicketType: "2", // 不为空 - 获取企业标识 为空 则为应用标识
          }).then((r) => {
            let d = r.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: d.corpId, // 必填，公众号的唯一标识
              timestamp: d.timestamp, // 必填，生成签名的时间戳
              nonceStr: d.nonceStr, // 必填，生成签名的随机串
              signature: d.signature, // 必填，签名，见附录1
              jsApiList: [
                "checkJsApi",
                "startLiving",
                "replayLiving",
                "agentConfig",
                "invoke",
                "startMeeting",
              ],
              openTagList: ["wx-open-launch-app"],
            });
            wx.ready(() => {
              wxworkConfig({
                url:
                  location.protocol +
                  "//" +
                  location.hostname +
                  location.pathname +
                  location.port +
                  location.search,
              }).then((res) => {
                let d2 = res.data;
                wx.agentConfig({
                  debug: true,
                  corpid: d2.corpId,
                  agentid: d2.agentid,
                  timestamp: d2.timestamp,
                  nonceStr: d2.nonceStr,
                  signature: d2.signature,
                  jsApiList: ["selectExternalContact"],
                  success: function (res) {
                    // 回调
                    console.log("agentConfigSuccess：" + JSON.stringify(res));
                  },
                  fail: function (res) {
                    if (res.errMsg.indexOf("function not exist") > -1) {
                      alert($t("the_version_is_too_low_please_upgrade"));
                      // alert("版本过低请升级");
                    }
                  },
                });
              });
            });
            wx.error((res) => {
              console.log(res, "---");
            });
          });
        } else {
          console.log("isWxWork web");
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.port +
              location.search,
          }).then((res) => {
            let d = res.data;
            wx.agentConfig({
              debug: true,
              corpid: d.corpId,
              agentid: d.agentid,
              timestamp: d.timestamp,
              nonceStr: d.nonceStr,
              signature: d.signature,
              jsApiList: ["selectExternalContact"],
              success: function (res) {
                // 回调
                console.log("agentConfigSuccess：" + JSON.stringify(res));
              },
              fail: function (res) {
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert($t("the_version_is_too_low_please_upgrade"));
                  // alert("版本过低请升级");
                }
              },
            });
          });
        }
      } else if (res.data.platform == 31 && isDingding) {
        let dingCorpid = ls.get("dingCorpid");
        let ddInit = window.DTOpenData.init(dingCorpid);
        console.log("ddInit:" + ddInit);
      }
    });

    // 试卷详情
    const examInfo = ref({
        totalScore: 0,
        passScore: 1,
      }),
      currentTime = Date.parse(new Date()) / 1000;
    let openFace = false;
    const getDetail = async () => {
      let method = getTaskDetail;

      if (isAdmin) {
        method = getTaskDetailLms;
      }
      if (reexamId) {
        method = getRetakeDetail;
      }
      // 课程考试
      let res = null;
      if (courseId) {
        state.params = {
          taskId: taskId,
          courseId: courseId,
          coursePlusId: detailId,
        };
        state.courseApi = "course/";
        res = await plusDetail({ courseId: courseId, taskId: taskId });
      } else {
        res = await method({ id: taskId, did: detailId, reexamId: reexamId });
      }
      if (res.ret === 0) {
        examInfo.value = res.data.exam;
        openFace =
          res.data.openFace == 1 || res.data.openFace == 3 ? true : false;
      }
    };
    getDetail();

    // 获取数据
    const paperData = ref({
      score: 0,
    });
    const getExamResult = () => {
      examResult(
        {
          ...state.params,
          paperId: paperId,
          submitId: submitId.value,
          reexamId: reexamId,
        },
        state.courseApi
      ).then((res) => {
        if (res.ret === 0) {
          paperData.value = res.data;
          state.faceList = res.data.openface || [];
          renderQues(res.data.questions);
        }
      });
    };

    const getSubmitPaperDetail = async () => {
      let res = null;
      if (courseId) {
        res = await coursExamPaperDetail({
          taskId: taskId,
          courseId: courseId,
          coursePlusId: detailId,
          userId: userId,
          submitId: submitId.value,
        });
      } else {
        res = await submitPaperDetail({
          taskId: taskId,
          detailId: detailId,
          userId: userId,
          submitId: submitId.value,
          reexamId: reexamId,
        });
      }
      if (res.ret === 0) {
        paperData.value = res.data;
        let platform = ls.get("platformConfig").platform;
        if (platform != 26 && platform != 31) {
          document.title = paperData.value.realName + "-" + $t("XB_ExamRecord");
          // 考试记录
        }
        state.faceList = res.data.openface || [];
        renderQues(res.data.questions);
      }
    };

    if (isAdmin) {
      getSubmitPaperDetail();
    } else {
      getExamResult();
    }

    const canEditScore = computed(() => {
      // 课程 - 考试不能修改分数
      return paperData.value.examStatus != 1 && isAdmin && !courseId;
    });

    // 数据处理
    const quesObj = ref(new Map());
    const rightNum = ref(0);
    const renderQues = (list) => {
      Object.keys(questionTypeEnum).forEach((key) => {
        quesObj.value.set(Number(key), {
          name: questionTypeEnum[key],
          type: Number(key),
          data: [],
        });
      });
      rightNum.value = 0;
      list.forEach((item, index) => {
        if (item.questionType === 1 || item.questionType === 3) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          for (let i = 0; i < item.options.length; i++) {
            if (item.options[i].id == item.answers[0]) item.correctAnswer = i;
            if (item.options[i].isRight) item.userAnswer = i;
          }
        } else if (item.questionType === 2) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          item.userAnswer = [];
          item.correctAnswer = [];
          for (let i = 0; i < item.options.length; i++) {
            for (let j = 0; j < item.answers.length; j++) {
              if (item.options[i].id == item.answers[j])
                item.correctAnswer.push(i);
            }
            if (item.options[i].isRight) item.userAnswer.push(i);
          }
        } else if (item.questionType === 4) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          item.editScore = item.score;
          item.userAnswer = item.answers || "";
          item.titles = item.title.split("＿");
          item.options = item.options || [];
          if (!item.options.length) {
            for (var i = 0; i < item.titles.length - 1; i++) {
              item.options.push({ id: i, title: "", isRight: false });
            }
          }
        } else if (item.questionType === 5) {
          if (item.myScore > 0) rightNum.value++;
          qusetionTotal.value++;
          item.editScore = item.score;
          item.userAnswer = item.answers || [];
          item.answerImg = item.answerImg || [];
          item.options = item.options || [];
          if (!item.options.length) {
            item.options = [{ id: 0, title: "", isRight: false }];
          }
          if (item.questionAnswers && item.questionAnswers != "[]") {
            item.answer1Arr = JSON.parse(item.questionAnswers);
          }
        } else if (item.questionType === 8) {
          caseRender(item.list);
        }
        quesObj.value.get(item.questionType).data.push(item);
      });
      quesObj.value.forEach(function (value, key) {
        value.totalScore = 0;
        //   quesObj.value.get(key - 1).data.length;
        for (let i = 0; i < value.data.length; i++) {
          value.totalScore += value.data[i].score;
        }
      });
      // 删除没有题目题型的map
      Object.keys(questionTypeEnum).forEach((key) => {
        if (quesObj.value.get(Number(key)).data.length == 0) {
          quesObj.value.delete(Number(key));
        }
      });
    };

    const caseRender = (list) => {
      list.forEach((item, index) => {
        if (item.myScore > 0) rightNum.value++;
        item.answers = item.answers || [];
        item.options = item.options || [];
        if (item.questionType === 1 || item.questionType === 3) {
          qusetionTotal.value++;
          for (let i = 0; i < item.options.length; i++) {
            if (item.options[i].id == item.answers[0]) item.correctAnswer = i;
            if (item.options[i].isRight) item.userAnswer = i;
          }
        } else if (item.questionType === 2) {
          qusetionTotal.value++;
          item.userAnswer = [];
          item.correctAnswer = [];
          for (let i = 0; i < item.options.length; i++) {
            for (let j = 0; j < item.answers.length; j++) {
              if (item.options[i].id == item.answers[j])
                item.correctAnswer.push(i);
            }
            if (item.options[i].isRight) item.userAnswer.push(i);
          }
        } else if (item.questionType === 4) {
          qusetionTotal.value++;
          item.editScore = item.score;
          item.userAnswer = item.answers || "";
          item.titles = item.title.split("＿");
          item.options = item.options || [];
          if (!item.options.length) {
            for (var i = 0; i < item.titles.length - 1; i++) {
              item.options.push({ id: i, title: "", isRight: false });
            }
          }
        } else if (item.questionType === 5) {
          qusetionTotal.value++;
          item.editScore = item.score;
          item.userAnswer = item.answers || [];
          item.answerImg = item.answerImg || [];
          item.options = item.options || [];
          if (!item.options.length) {
            item.options = [{ id: 0, title: "", isRight: false }];
          }
          if (item.questionAnswers && item.questionAnswers != "[]") {
            item.answer1Arr = JSON.parse(item.questionAnswers);
          }
        }
      });
    };

    // 答题卡定位
    const scrollTo = (id) => {
      document.getElementById("que_" + id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    };

    const jump = () => {
      let url = `/exam/paper?taskId=${taskId}&detailId=${detailId}&openFace=${
        openFace ? "10" : examInfo.value.openFace
      }&switchNum=${examInfo.value.switchNum}`;
      window.location.href = url;
    };

    const historyChange = (id) => {
      submitId.value = id;
      getExamResult();
    };

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    const exportPaper = () => {
      setTimeout(() => {
        window.print();
      }, 500);
    };

    const editScore = (item) => {
      examModify({
        taskId: taskId,
        detailId: detailId,
        submitId: submitId.value,
        questionIdList: [item.questionId],
        score: item.editScore || item.score,
        userId,
      }).then((res) => {
        if (res.ret == 0) {
          item.myScore = item.editScore || item.score;
          if (item.questionType == 4) {
            if (item.strAnswer) {
              let ans = JSON.parse(item.strAnswer);
              item.options.forEach((opt, optI) => {
                opt.title = ans[optI].split("$")[0];
                opt.isRight = true;
              });
            }
          } else {
            item.options.forEach((opt, optI) => {
              if (item.answers.includes(optI)) {
                opt.isRight = true;
              } else {
                opt.isRight = false;
              }
            });
          }
          renderQues(paperData.value.questions);
          paperData.value.score = res.data.score;
          paperData.value.status = res.data.examStatus;
        }
      });
    };

    return {
      ...toRefs(state),
      isAdmin,
      questionTypeEnum,
      submitId,
      qusetionTotal,
      letterOpts,
      toChinesNum,
      dateFormat,
      formatSeconds,
      isWxWork,
      isMac,
      examInfo,
      currentTime,
      paperData,
      quesObj,
      rightNum,
      scrollTo,
      jump,
      historyChange,
      getResourceType,
      exportPaper,
      editScore,
      canEditScore,
      state,
    };
  },
};
</script>

<style>
#app {
  height: auto;
}
</style>
<style lang="less" scoped>
.record {
  background-color: #eff1f4;
  padding-top: 24px;
}
.head {
  .mixinWrap(1230px);
  padding: 24px;
  background-color: #fff;
  h1 {
    color: #333;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
    .mixinEllipsis(50px);
    text-align: center;
  }
  &-info {
    .mixinFlex(space-between; center);
    .user {
      .mixinFlex(space-between; center);
      .img {
        .mixinImgWrap(60px; 60px);
        border-radius: 50%;
        margin-right: 10px;
      }
      p {
        margin: 0;
        font-size: 16px;
        span {
          padding-right: 30px;
        }
      }
      .depart {
        span {
          padding-right: 0;
        }
      }
    }
    .signature {
      width: 150px;
      height: 70px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.statistics {
  margin-top: 24px;
  .content {
    .mixinWrap(1230px);
    background-color: #fff;
    padding: 12px 24px;
    p {
      margin: 12px 0;
      font-size: 16px;
    }
    .historys {
      .mixinFlex(flex-end; center);
      height: 100%;
    }
  }
}
.main {
  background-color: @color-page-light;
  padding-bottom: 80px;
  .content {
    .mixinWrap(1230px);
    .mixinFlex(space-between);
    position: relative;
  }
  .questions {
    width: 894px;
    .headline {
      line-height: 68px;
      color: #202020;
      .classify {
        font-size: 20px;
      }
      .score {
        font-size: 16px;
      }
    }
    &-item {
      padding: 24px;
      background-color: #fff;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &-head {
        margin-bottom: 16px;
        .mixinFlex(space-between; center);
        .type-title {
          color: #e10808;
          font-size: 18px;
          line-height: 30px;
          span {
            color: #202020;
          }
        }
      }
      .title {
        font-size: 18px;
        line-height: 1.7;
        color: #202020;
        margin: 16px 0;
      }
      .opt-checkbox,
      .opt-radio {
        // .mixinFlex(flex-start; center);
        white-space: normal !important;
        .opt-item {
          .mixinFlex(flex-start; flex-start);
          font-size: 16px;
          margin-bottom: 16px;
          .letter {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            .mixinFlex(center; center);
            margin-right: 20px;
            border: 1px solid #dddddd;
          }
          .text {
            color: #202020;
            line-height: 28px;
            max-width: calc(100% - 48px);
          }
        }
        &.active {
          .letter {
            border: none;
            background-color: #f65464;
            color: #fff;
          }
          .text {
            color: #f65464;
          }
        }
        &.right {
          .letter {
            border: none;
            background-color: #266fff;
            color: #fff;
          }
          .text {
            color: #266fff;
          }
        }
        .letter.wrong {
          border: none;
          background-color: #f65464;
          color: #fff;
        }
        .text.wrong {
          color: #f65464;
        }
      }
      .answer {
        background-color: #f9f9f9;
        padding: 22px 24px;
        .mixinFlex(space-between);
        .symbol {
          width: 20px;
          height: 20px;
          font-size: 22px;
          line-height: 28px;
          .right {
            color: #1ec88b;
          }
          .wrong {
            color: #ff4c50;
          }
        }
        .info {
          width: calc(100% - 34px);
          font-size: 16px;
          line-height: 28px;
          color: #333;
          p {
            margin-bottom: 0;
            word-break: break-all;
            & > span {
              padding-right: 50px;
              &:last-child {
                padding-right: 0;
              }
              &.right {
                i {
                  color: #266fff;
                }
              }
              &.wrong {
                i {
                  color: #f65464;
                }
              }
            }
          }
          .edit-score {
            color: @color-theme;
            cursor: pointer;
            span {
              padding-right: 5px;
            }
          }
          b {
            font-weight: normal;
            color: #000;
          }
          i {
            font-style: normal;
            letter-spacing: 1px;
          }
        }
      }
      .opt-input {
        border-bottom: 1px solid #202020;
        padding: 1px 4px;
        b {
          font-weight: normal;
          padding: 0 20px;
          position: relative;
          span {
            font-size: 20px;
            color: #266fff;
          }
          i {
            font-style: normal;
            color: #266fff;
            background-color: #e9effa;
            padding: 0 6px 0 3px;
            margin-right: 3px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              right: 0;
              width: 3px;
              background-color: #fff;
            }
            &:last-child {
              padding-right: 3px;
              &::after {
                width: 0;
              }
            }
          }
        }
      }
      .opt-textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        min-height: 120px;
        margin-top: 4px;
        font-size: 16px;
        margin-bottom: 12px;
      }
      .opt-pics {
        margin-bottom: 12px;
        &-item {
          display: inline-block;
          width: 105px;
          height: 105px;
          margin-right: 10px;
          border-radius: 4px;
          position: relative;
          &.ed {
            width: 105px;
            height: 105px;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            font-size: 0;
            position: relative;
            user-select: none;
            display: inline-flex;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }
    }
    .case-box {
      padding: 12px;
      background-color: #fafafa;
      &-head {
        .title {
          font-size: 18px;
          line-height: 1.7;
          color: #202020;
          margin: 0 0 16px;
        }
      }
    }
  }
  .sidebar {
    width: 310px;
    padding-top: 68px;
    position: relative;
    &-content {
      position: sticky;
      top: 24px;
    }
    .sheet {
      background-color: #fff;
      padding: 20px 16px;
      h5 {
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-bottom: 0;
        font-weight: 500;
        text-align: center;
      }
      .label {
        padding: 16px 0 20px;
        font-size: 16px;
        color: #333;
        .mixinFlex(space-around);
        .item {
          .mixinFlex(flex-start; center);
          span {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            border-radius: 2px;
          }
          &:nth-child(1) {
            span {
              background-color: #447dff;
            }
          }
          &:nth-child(2) {
            span {
              background-color: #f65464;
            }
          }
          &:nth-child(3) {
            span {
              border: 1px solid #999999;
            }
          }
        }
      }
      .scrollbar();
      .sheet-list {
        max-height: 400px;
        height: calc(100vh - 250px);
        overflow-y: auto;
        .type-path {
          .classify {
            font-size: 16px;
            color: #333;
            line-height: 26px;
            margin-bottom: 8px;
          }
          .list {
            .item {
              float: left;
              width: 36px;
              height: 40px;
              overflow: hidden;
              border-radius: 2px;
              border: 1px solid #999999;
              cursor: pointer;
              margin-right: 14px;
              margin-bottom: 14px;
              color: #999999;
              font-size: 18px;
              position: relative;
              .mixinFlex(center; center);
              &:nth-child(5n) {
                margin-right: 0;
              }
              &.right {
                border-color: #447dff;
                color: #447dff;
              }
              &.wrong {
                border-color: #f65464;
                color: #f65464;
              }
            }
          }
        }
      }
      .btn {
        padding-top: 20px;
        .ant-btn {
          height: 48px;
          font-size: 16px;
          background-color: #447dff;
          border-radius: 4px;
        }
      }
    }
  }
}
.face-record {
  margin-top: 24px;
  .content {
    .mixinWrap(1230px);
    background-color: #fff;
    padding: 12px 24px;
    .title {
      margin: 10px 0;
      font-size: 16px;
      font-weight: 600;
    }
    .face {
      display: flex;
      flex-wrap: wrap;
      max-height: 600px;
      overflow-y: auto;
      &-item {
        position: relative;
        width: 150px;
        margin: 5px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
        .img {
          height: 150px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .info {
          padding: 10px;
          line-height: 24px;
          .success {
            color: #22cf33;
          }
          .error {
            color: #ff0000;
          }
        }
        .tag {
          position: absolute;
          top: 2px;
          right: 2px;
          padding: 1px 8px;
          font-size: 12px;
          color: #fff;
          background-color: #409eff;
          border-radius: 4px;
        }
      }
    }
  }
}

@media print {
  @page {
    size: auto;
    margin: 0.6cm;
  }
  .statistics .content,
  .main .content,
  .head {
    .mixinWrap(1100px);
  }
  .time {
    letter-spacing: -1px;
  }
  .no-print {
    display: none !important;
  }
  video,
  audio {
    display: none;
  }
}
.water-marks {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 0;
  left: 0;
  top: 34px;
  bottom: 0;
  overflow: hidden;
  .item {
    float: left;
    transform: rotate(-20deg);
    margin: 80px 50px 250px 50px;
    font-size: 18px;
    line-height: 40px;
    color: rgba(170, 170, 170, 0.2);
    pointer-events: none;
  }
}
</style>
